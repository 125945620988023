/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import ModalDownload from "./modal/modaldownload"
import Cookies from "./cookies/cookies"
import Nav from "./nav/nav"
import Footer from "./footer/footer"
import "./layout.scss"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div class="layout">
        <ModalDownload />
        <Cookies />
        <Nav siteTitle={data.site.siteMetadata.title} navstate={props.navstate} trialbtnClass={props.trialbtnClass} />
        <main>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout