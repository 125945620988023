import React from "react"
import { Link } from "gatsby"
import buttonStyles from "./button.module.scss"

export const LinkBody = props => (
    <Link to={props.to} className={buttonStyles.linkbody}>
        {props.children}
    </Link>
)

export const BtnTextInvertedIconr = props => (
    <Link to={props.to} className={buttonStyles.btnTextInverted}>
        {props.children}
        <i className={props.icon + ' ' + buttonStyles.btnIconr}></i>
    </Link>
)

export const BtnTextPrimaryIconr = props => (
    <Link to={props.to} className={buttonStyles.btnTextPrimary}>
        {props.children}
        <i className={props.icon + ' ' + buttonStyles.btnIconr}></i>
    </Link>
)

export const BtnContainedPrimaryFull = props => (
    <a href="#openModal" className={buttonStyles.btnContainedPrimaryFull}>
        {props.children}
    </a>
)

export const BtnContainedPrimaryLink = props => (
    <Link to={props.to} className={buttonStyles.btnContainedPrimaryIcon}>
        {props.children}
        <i className={props.icon + ' ' + buttonStyles.btnIconr}></i>
    </Link>
)

export const BtnContainedPrimary = props => (
    <button type="submit" value="Send" className={buttonStyles.btnContainedPrimary}>
        {props.children}
    </button>
)

export const BtnContainedPrimaryIconr = props => (
    <button type="submit" value="Send" className={buttonStyles.btnContainedPrimaryIcon}>
        {props.children}
        <i className={props.icon + ' ' + buttonStyles.btnIconr}></i>
    </button>
)
