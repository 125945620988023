import React from "react"
import divisorStyles from "./divisor.module.scss"

export const DivisorLarge = () =>(
    <div className={divisorStyles.divisorlarge}></div>
)

export const DivisorMedium = () =>(
    <div className={divisorStyles.divisormedium}></div>
)

export const DivisorOr = () =>(
    <div className={divisorStyles.divisoror}>
        <span>o</span>
        <div className={divisorStyles.line}></div>
    </div>
)
