import React from "react"
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby"

import cookiesStyles from "./cookies.module.scss"

const Cookies = () => (
    <CookieConsent
    // debug={true}
    buttonText="Aceptar"
    disableStyles={true}
    containerClasses={cookiesStyles.container}
    contentClasses={cookiesStyles.content}
    buttonClasses={cookiesStyles.btn}
    >
    Utilizamos cookies para ofrecerte una mejor experiencia y analizar el tráfico de nuestra web. Al continuar navegando, aceptas la <Link to="/politica-de-cookies/">Política de cookies</Link>.
    </CookieConsent>
)

export default Cookies