import React from "react"
import { Link } from "gatsby"

import { Container, Grid } from "../grid/grid"

import footerStyles from "./footer.module.scss"


const FooterLink = props => (
    <Link to={props.to} className={footerStyles.textLink}>
        {props.children}
    </Link>
)

const SocialLink = props => (
    <a href={props.href} className={footerStyles.socialLink} target="_blank" rel="noopener noreferrer">
        <i class={props.icon}></i>
    </a>
)

const Footer = () => (
    <footer>
        <Container>
            <Grid class="grid-s-double">
                <div className={footerStyles.brand + " m-start-1 m-end-3 s-start-1 s-end-3"}>
                    <svg className={footerStyles.logo} version="1.1" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 395.4 79.6" aria-labelledby="parquick" role="img">
                        <title id="parquick">Parquick</title>
                    
                        <defs>
                        <mask id="mask">>
                            <rect width="100%" height="100%" fill="white"/>
                            <path class="st1" d="M67.7,14.4H57.2l-2.4-4.4c-0.6-1.2-1.8-1.9-3.1-1.9h-2.9l1.3,6.3H9.4c-5.2,0-9.4,4.2-9.4,9.4v16.1
                            c0,5.2,4.2,9.4,9.4,9.4H50l-1.3,6.3h2.9c1.3,0,2.5-0.7,3.1-1.9l2.4-4.4h10.5c8.4,0,15.2-6.8,15.2-15.2v-4.5
                            C82.9,21.2,76.1,14.4,67.7,14.4z" fill="black"/>
                        </mask>
                        </defs>
                        <path class="st0" d="M70.9,0H19.2L3.3,79.6h7.2l23.2-16h34.8c15.8,0,29.5-11.2,32.6-26.7l0,0C104.9,17.8,90.3,0,70.9,0z" mask="url(#mask)"/>
                        
                        <g>
                        <path class="st0" d="M161.3,46.9c1.7-3,2.5-6.2,2.5-9.7c0-4.1-1.3-7.5-3.9-10.2c-2.6-2.7-6-4-10.2-4c-3.8,0-7.5,1.3-10.8,3.9
                            l-0.4,0.3l-0.7-3.4h-5l-8.7,43.5h7.7l3-14.8l0.5,0.4c2.4,2.3,5.5,3.5,9.3,3.5c3.6,0,6.9-0.9,9.8-2.6
                            C157.3,52.2,159.6,49.9,161.3,46.9z M152.7,45.9c-2.2,2.3-4.9,3.5-8,3.5c-2.4,0-4.3-0.7-5.7-2c-1.5-1.3-2.2-3.2-2.2-5.4
                            c0-3.1,1.1-5.9,3.4-8.3c2.2-2.4,4.9-3.6,8-3.6c2.4,0,4.3,0.7,5.7,2.1c1.4,1.4,2.2,3.2,2.2,5.5C156,40.8,154.9,43.6,152.7,45.9z"/>
                        <path class="st0" d="M200,23.9l-2,4.2l-0.4-0.4c-1.2-1.5-2.7-2.6-4.4-3.5c-1.8-0.8-3.7-1.2-5.9-1.2c-3.5,0-6.8,0.9-9.8,2.6
                            c-3,1.7-5.3,4.1-7,7.1c-1.7,3-2.6,6.2-2.6,9.7c0,4.1,1.3,7.5,3.9,10.1c2.6,2.7,6,4,10.2,4c4.1,0,7.8-1.3,11.1-3.8l0.5-0.4l0.2,3.4
                            h5.6l6.4-31.8H200z M191.6,45.9c-2.3,2.3-4.9,3.5-8,3.5c-2.4,0-4.3-0.7-5.7-2.1c-1.4-1.4-2.2-3.2-2.2-5.5c0-3.1,1.1-5.9,3.4-8.2
                            c2.2-2.3,4.9-3.5,7.9-3.5c2.4,0,4.3,0.7,5.8,2.1c1.5,1.4,2.2,3.2,2.2,5.5C195,40.8,193.8,43.5,191.6,45.9z"/>
                        <path class="st0" d="M230.7,23.7c-4,0-7.4,1.3-10.2,3.9L220,28l-0.7-4.1h-4.9l-6.4,31.8h7.7l3.5-17.4c0.6-2.5,1.6-4.4,3-5.6
                            c1.4-1.2,3.4-1.8,5.9-1.8h3.5l1.4-7.2H230.7z"/>
                        <path class="st0" d="M265.1,23.9l-2.6,3.9l-0.3-0.4c-1.2-1.4-2.7-2.5-4.4-3.3c-1.7-0.8-3.7-1.2-5.8-1.2c-2.6,0-5.2,0.5-7.6,1.6
                            c-2.4,1-4.5,2.4-6.2,4.2c-1.7,1.7-3.1,3.8-4.1,6.2c-1,2.4-1.5,4.9-1.5,7.5c0,4.1,1.3,7.5,4,10.1c2.6,2.7,6.1,4,10.3,4
                            c3.1,0,6-0.9,8.8-2.7l0.7-0.4l-2.8,13.9h7.6l8.7-43.5H265.1z M256.4,45.9c-2.2,2.3-4.8,3.5-7.8,3.5c-2.4,0-4.3-0.7-5.8-2.1
                            c-1.5-1.4-2.3-3.2-2.3-5.4c0-3.1,1.1-5.8,3.3-8.2c2.2-2.4,4.9-3.6,7.9-3.6c2.4,0,4.3,0.7,5.8,2.1c1.5,1.4,2.2,3.2,2.2,5.4
                            C259.7,40.8,258.6,43.5,256.4,45.9z"/>
                        <polygon class="st0" points="315.6,23.9 309.2,55.7 316.9,55.7 323.2,23.9 	"/>
                        <path class="st0" d="M358.2,43.8h-7.7c-1.1,1.8-2.4,3.1-4,4.1c-1.6,1-3.4,1.5-5.3,1.5c-2.2,0-3.9-0.7-5.3-2.1c-1.3-1.4-2-3.2-2-5.4
                            c0-3,1.1-5.8,3.2-8.2c2.2-2.4,4.7-3.6,7.6-3.6c1.9,0,3.4,0.5,4.7,1.5c1.2,0.9,2,2.2,2.5,3.8h7.7c-0.2-3.5-1.6-6.5-4-8.8
                            c-2.5-2.4-5.8-3.6-9.8-3.6c-3.5,0-6.8,0.9-9.9,2.6c-3,1.7-5.4,4.1-7.2,7.1c-1.8,3-2.6,6.2-2.6,9.7c0,4,1.3,7.5,3.9,10.1
                            c2.6,2.7,6,4,10.1,4c4.1,0,7.9-1.2,11.1-3.5C354.3,50.9,356.7,47.7,358.2,43.8z"/>
                        <polygon class="st0" points="395.4,23.9 386.1,23.9 373,38.4 378.2,12.2 370.5,12.2 361.8,55.7 369.5,55.7 372.9,38.9 382,55.7 
                            390.2,55.7 381.2,39.3 	"/>
                        <polygon class="st0" points="317.7,11.5 316.3,18.7 324.4,18.7 325.8,11.5 	"/>
                        <path class="st0" d="M303.8,42.8l3.8-18.9h-7.6l-3.4,17.2c-0.5,2.5-1.5,4.5-3.1,6.1c-1.6,1.6-3.5,2.3-5.7,2.4
                            c-2.1,0-3.5-0.6-4.4-1.4c-1.9-1.8-1.6-4.4-1.3-6.2c0.2-1.1,3.6-17.9,3.6-17.9h-7.7c-0.3,1.6-3.1,15.1-3.2,15.8
                            c-1,4.7-1.5,9.7,2.7,13.7c2.2,2.1,5.4,3.2,9.2,3.2c4.5,0,8.3-1.2,11.3-3.7C301,50.5,302.9,47.1,303.8,42.8z"/>
                        </g>
                        <path class="st0" d="M56.7,19.2l-10.9,1.4c0,0,1.6,4.6,1.6,11.2S45.8,43,45.8,43l10.9,1.4c1.8,0.2,3.5-0.9,3.9-2.7
                            c0.6-2.3,1.2-5.8,1.2-9.9c0-4.1-0.7-7.6-1.2-9.9C60.2,20.1,58.5,18.9,56.7,19.2z"/>
                        <path class="st0" d="M13.3,21.5l7.8-1c0,0-1.6,5.1-1.6,11.4S21,43,21,43l-7.8-1c-1.6-0.2-2.8-1.4-3.1-2.9c-0.3-1.8-0.7-4.4-0.7-7.4
                            c0-3,0.3-5.6,0.7-7.4C10.5,22.9,11.7,21.7,13.3,21.5z"/>
                    </svg>
                    <div className={footerStyles.copyright}>
                        © {new Date().getFullYear()} Parquick SL
                    </div>
                </div>

                <div class="l-start-4 l-end-7 m-start-3 m-end-5">
                    <div className={footerStyles.title}>Contacto</div>
                    <a href="mailto:info.@parquick.com" className={footerStyles.textLink}>info@parquick.com</a>
                </div>

                <div class="l-start-7 l-end-9 m-start-5 m-end-6">
                    <div className={footerStyles.title}>Servicio</div>
                    <FooterLink to="encontrar-parking-barcelona">Encontrar</FooterLink>
                    <FooterLink to="ahorrar-aparcamiento-barcelona">Ahorrar</FooterLink>
                    <FooterLink to="parkings">Para parkings</FooterLink>
                </div>

                <div class="l-start-9 l-end-11 m-start-6 m-end-7">
                    <div className={footerStyles.title}>Legal</div>
                    <FooterLink to="politica-de-privacidad">Privacidad</FooterLink>
                    <FooterLink to="politica-de-cookies">Cookies</FooterLink>
                </div>

                <div className={footerStyles.social + " l-start-11 l-end-13 m-start-1 m-end-7"}>
                    <SocialLink href="https://twitter.com/parquick" icon="icon-social-twitter_32"/>
                    <SocialLink href="http://instagram.com/parquick" icon="icon-social-instagram_32"/>
                    <SocialLink href="https://www.facebook.com/parquick/" icon="icon-social-facebook_32"/>
                </div>
            </Grid>
        </Container>
    </footer>

)

export default Footer