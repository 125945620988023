import React from "react"

export const Container = props => (
    <div id={props.id} class={"container " + [props.class]}>
        {props.children}
    </div>
)

export const Grid = props => (
    <div class={"grid " + [props.class]}>
        {props.children}
    </div>
)