import React from "react"

import modalStyles from "./modal.module.scss"

const Modal = props => (

    <div id={props.id} class={modalStyles.modalDialog}>
        <div class={modalStyles.modalContent}>
            {props.children}
        </div>
        <a href={props.idclose} title="Close" class={modalStyles.backClose}></a>
    </div>
)

export default Modal