import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

import navStyles from "./nav.module.scss"

const BtnNav = props =>(
  <Link to={props.to} className={navStyles.btnNav}>
        {props.children}
  </Link>
)

const BtnContained = props =>(
  <a href="#openModal" className={navStyles.btnContained + ' ' + props.class}>
        {props.children}
  </a>
)

const Nav = props => (
  <nav className={navStyles.nav + ' ' + navStyles[props.navstate]}>
    <Link className={navStyles.logo} to="/">
      <svg className={navStyles.logoDesktop} version="1.1" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 395.4 79.6" aria-labelledby="parquick" role="img">
        <title id="parquick">{props.siteTitle}</title>
      
        <defs>
          <mask id="carmask">
            <rect width="100%" height="100%" fill="white"/>
            <path id={navStyles.car} class="st1" d="M67.7,14.4H57.2l-2.4-4.4c-0.6-1.2-1.8-1.9-3.1-1.9h-2.9l1.3,6.3H9.4c-5.2,0-9.4,4.2-9.4,9.4v16.1
              c0,5.2,4.2,9.4,9.4,9.4H50l-1.3,6.3h2.9c1.3,0,2.5-0.7,3.1-1.9l2.4-4.4h10.5c8.4,0,15.2-6.8,15.2-15.2v-4.5
              C82.9,21.2,76.1,14.4,67.7,14.4z" fill="black"/>
          </mask>
        </defs>
        <path class="st0" d="M70.9,0H19.2L3.3,79.6h7.2l23.2-16h34.8c15.8,0,29.5-11.2,32.6-26.7l0,0C104.9,17.8,90.3,0,70.9,0z" mask="url(#carmask)"/>
        
        <g>
          <path class="st0" d="M161.3,46.9c1.7-3,2.5-6.2,2.5-9.7c0-4.1-1.3-7.5-3.9-10.2c-2.6-2.7-6-4-10.2-4c-3.8,0-7.5,1.3-10.8,3.9
            l-0.4,0.3l-0.7-3.4h-5l-8.7,43.5h7.7l3-14.8l0.5,0.4c2.4,2.3,5.5,3.5,9.3,3.5c3.6,0,6.9-0.9,9.8-2.6
            C157.3,52.2,159.6,49.9,161.3,46.9z M152.7,45.9c-2.2,2.3-4.9,3.5-8,3.5c-2.4,0-4.3-0.7-5.7-2c-1.5-1.3-2.2-3.2-2.2-5.4
            c0-3.1,1.1-5.9,3.4-8.3c2.2-2.4,4.9-3.6,8-3.6c2.4,0,4.3,0.7,5.7,2.1c1.4,1.4,2.2,3.2,2.2,5.5C156,40.8,154.9,43.6,152.7,45.9z"/>
          <path class="st0" d="M200,23.9l-2,4.2l-0.4-0.4c-1.2-1.5-2.7-2.6-4.4-3.5c-1.8-0.8-3.7-1.2-5.9-1.2c-3.5,0-6.8,0.9-9.8,2.6
            c-3,1.7-5.3,4.1-7,7.1c-1.7,3-2.6,6.2-2.6,9.7c0,4.1,1.3,7.5,3.9,10.1c2.6,2.7,6,4,10.2,4c4.1,0,7.8-1.3,11.1-3.8l0.5-0.4l0.2,3.4
            h5.6l6.4-31.8H200z M191.6,45.9c-2.3,2.3-4.9,3.5-8,3.5c-2.4,0-4.3-0.7-5.7-2.1c-1.4-1.4-2.2-3.2-2.2-5.5c0-3.1,1.1-5.9,3.4-8.2
            c2.2-2.3,4.9-3.5,7.9-3.5c2.4,0,4.3,0.7,5.8,2.1c1.5,1.4,2.2,3.2,2.2,5.5C195,40.8,193.8,43.5,191.6,45.9z"/>
          <path class="st0" d="M230.7,23.7c-4,0-7.4,1.3-10.2,3.9L220,28l-0.7-4.1h-4.9l-6.4,31.8h7.7l3.5-17.4c0.6-2.5,1.6-4.4,3-5.6
            c1.4-1.2,3.4-1.8,5.9-1.8h3.5l1.4-7.2H230.7z"/>
          <path class="st0" d="M265.1,23.9l-2.6,3.9l-0.3-0.4c-1.2-1.4-2.7-2.5-4.4-3.3c-1.7-0.8-3.7-1.2-5.8-1.2c-2.6,0-5.2,0.5-7.6,1.6
            c-2.4,1-4.5,2.4-6.2,4.2c-1.7,1.7-3.1,3.8-4.1,6.2c-1,2.4-1.5,4.9-1.5,7.5c0,4.1,1.3,7.5,4,10.1c2.6,2.7,6.1,4,10.3,4
            c3.1,0,6-0.9,8.8-2.7l0.7-0.4l-2.8,13.9h7.6l8.7-43.5H265.1z M256.4,45.9c-2.2,2.3-4.8,3.5-7.8,3.5c-2.4,0-4.3-0.7-5.8-2.1
            c-1.5-1.4-2.3-3.2-2.3-5.4c0-3.1,1.1-5.8,3.3-8.2c2.2-2.4,4.9-3.6,7.9-3.6c2.4,0,4.3,0.7,5.8,2.1c1.5,1.4,2.2,3.2,2.2,5.4
            C259.7,40.8,258.6,43.5,256.4,45.9z"/>
          <polygon class="st0" points="315.6,23.9 309.2,55.7 316.9,55.7 323.2,23.9 	"/>
          <path class="st0" d="M358.2,43.8h-7.7c-1.1,1.8-2.4,3.1-4,4.1c-1.6,1-3.4,1.5-5.3,1.5c-2.2,0-3.9-0.7-5.3-2.1c-1.3-1.4-2-3.2-2-5.4
            c0-3,1.1-5.8,3.2-8.2c2.2-2.4,4.7-3.6,7.6-3.6c1.9,0,3.4,0.5,4.7,1.5c1.2,0.9,2,2.2,2.5,3.8h7.7c-0.2-3.5-1.6-6.5-4-8.8
            c-2.5-2.4-5.8-3.6-9.8-3.6c-3.5,0-6.8,0.9-9.9,2.6c-3,1.7-5.4,4.1-7.2,7.1c-1.8,3-2.6,6.2-2.6,9.7c0,4,1.3,7.5,3.9,10.1
            c2.6,2.7,6,4,10.1,4c4.1,0,7.9-1.2,11.1-3.5C354.3,50.9,356.7,47.7,358.2,43.8z"/>
          <polygon class="st0" points="395.4,23.9 386.1,23.9 373,38.4 378.2,12.2 370.5,12.2 361.8,55.7 369.5,55.7 372.9,38.9 382,55.7 
            390.2,55.7 381.2,39.3 	"/>
          <polygon class="st0" points="317.7,11.5 316.3,18.7 324.4,18.7 325.8,11.5 	"/>
          <path class="st0" d="M303.8,42.8l3.8-18.9h-7.6l-3.4,17.2c-0.5,2.5-1.5,4.5-3.1,6.1c-1.6,1.6-3.5,2.3-5.7,2.4
            c-2.1,0-3.5-0.6-4.4-1.4c-1.9-1.8-1.6-4.4-1.3-6.2c0.2-1.1,3.6-17.9,3.6-17.9h-7.7c-0.3,1.6-3.1,15.1-3.2,15.8
            c-1,4.7-1.5,9.7,2.7,13.7c2.2,2.1,5.4,3.2,9.2,3.2c4.5,0,8.3-1.2,11.3-3.7C301,50.5,302.9,47.1,303.8,42.8z"/>
        </g>
        <g id={navStyles.carwindows}>
          <path class="st0" d="M56.7,19.2l-10.9,1.4c0,0,1.6,4.6,1.6,11.2S45.8,43,45.8,43l10.9,1.4c1.8,0.2,3.5-0.9,3.9-2.7
            c0.6-2.3,1.2-5.8,1.2-9.9c0-4.1-0.7-7.6-1.2-9.9C60.2,20.1,58.5,18.9,56.7,19.2z"/>
          <path class="st0" d="M13.3,21.5l7.8-1c0,0-1.6,5.1-1.6,11.4S21,43,21,43l-7.8-1c-1.6-0.2-2.8-1.4-3.1-2.9c-0.3-1.8-0.7-4.4-0.7-7.4
            c0-3,0.3-5.6,0.7-7.4C10.5,22.9,11.7,21.7,13.3,21.5z"/>
        </g>
      </svg>

      <svg className={navStyles.logoMobile} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
          viewBox="0 0 392.15726 79.60181">
        <g>
          <path class="st0" d="M146.49342,23.01452c-3.84167,0-7.45837,1.31067-10.75476,3.89441l-0.44189,0.34668l-0.7359-3.35919h-4.98511
            l-8.71326,43.5318h7.66315l2.95374-14.79108l0.46423,0.44702c2.42871,2.33545,5.5426,3.52039,9.25452,3.52039
            c3.56604,0,6.87659-0.88391,9.83844-2.62732c2.96185-1.74451,5.3175-4.12659,7.00421-7.07825
            c1.68671-2.95172,2.54224-6.21771,2.54224-9.70764c0-4.08093-1.32178-7.50097-3.92889-10.16479
            C154.04909,24.36474,150.63112,23.01452,146.49342,23.01452z M149.36403,45.90862
            c-2.23407,2.31512-4.91211,3.48895-7.95911,3.48895c-2.35565,0-4.28162-0.67914-5.72504-2.0202
            c-1.45154-1.34912-2.18744-3.16254-2.18744-5.39154c0-3.11896,1.13531-5.89734,3.37744-8.25916
            c2.2442-2.36481,4.92834-3.56396,7.97534-3.56396c2.35773,0,4.28162,0.69537,5.7149,2.06787
            c1.43939,1.3775,2.16919,3.21527,2.16919,5.46149C152.72932,40.83025,151.5981,43.59447,149.36403,45.90862z"/>
          <path class="st0" d="M194.64943,28.14153l-0.35071-0.43488c-1.19611-1.47888-2.6922-2.64355-4.44788-3.45953
            c-1.75964-0.81799-3.73425-1.2326-5.86694-1.2326c-3.54572,0-6.84613,0.88391-9.80798,2.62738
            c-2.95984,1.74347-5.32159,4.13464-7.01843,7.10663s-2.5564,6.23895-2.5564,9.70764c0,4.0647,1.32788,7.47455,3.94305,10.13641
            c2.61316,2.6618,6.04535,4.01196,10.20331,4.01196c4.09711,0,7.8233-1.29138,11.0791-3.83765l0.52509-0.41052l0.22705,3.36633
            h5.58716l6.35956-31.82629h-5.84668L194.64943,28.14153z M188.26759,45.87822c-2.25641,2.33539-4.93445,3.51935-7.96118,3.51935
            c-2.35773,0-4.27954-0.69537-5.71283-2.06781c-1.43939-1.37756-2.16919-3.21527-2.16919-5.46149
            c0-3.09875,1.13123-5.85278,3.36322-8.18518c2.23407-2.33642,4.89386-3.52038,7.90234-3.52038
            c2.37799,0,4.32013,0.70044,5.77368,2.08203c1.45764,1.3877,2.19757,3.23047,2.19757,5.47571
            C191.66121,40.80089,190.51985,43.54479,188.26759,45.87822z"/>
          <path class="st0" d="M227.37178,23.72002c-4.00793,0-7.44012,1.30457-10.20129,3.8772l-0.48248,0.44904l-0.67712-4.14984h-4.85126
            l-6.35956,31.82629h7.66309l3.5011-17.42554c0.61023-2.54828,1.63403-4.42755,3.04907-5.59833
            c1.42108-1.17578,3.40173-1.77185,5.88721-1.77185h3.47473l1.44751-7.20697H227.37178z"/>
          <path class="st0" d="M259.20007,27.83135l-0.30408-0.35071c-1.21637-1.40692-2.70441-2.51385-4.42351-3.29132
            c-1.71918-0.77948-3.65521-1.1748-5.7514-1.1748c-2.64355,0-5.19391,0.52405-7.58203,1.55798
            c-2.3902,1.039-4.46002,2.44287-6.15076,4.17419c-1.69684,1.73328-3.06732,3.8194-4.0769,6.20044
            c-1.00549,2.37189-1.51434,4.89893-1.51434,7.50903c0,4.06268,1.33594,7.47357,3.97144,10.13538
            c2.63544,2.66284,6.08789,4.013,10.2641,4.013c3.07538,0,6.04944-0.89197,8.841-2.65271l0.69537-0.43787l-2.78351,13.91425h7.63475
            l8.71326-43.5318h-4.91211L259.20007,27.83135z M253.14058,45.85997c-2.18951,2.32831-4.82294,3.50922-7.83142,3.50922
            c-2.37598,0-4.33636-0.6994-5.82843-2.08002c-1.50018-1.38763-2.26044-3.21118-2.26044-5.4209
            c0-3.08051,1.12518-5.83453,3.34503-8.18317c2.22797-2.35773,4.88171-3.55279,7.8902-3.55279
            c2.37598,0,4.31812,0.69031,5.77167,2.05163c1.45966,1.36639,2.19957,3.19904,2.19957,5.44928
            C256.42676,40.7705,255.32191,43.53771,253.14058,45.85997z"/>
          <polygon class="st0" points="305.91675,55.72271 313.57983,55.72271 319.93945,23.89641 312.27631,23.89641 	"/>
          <path class="st0" d="M341.44482,30.16271c1.85498,0,3.42816,0.48962,4.67291,1.4566
            c1.17987,0.91632,2.03131,2.20569,2.53204,3.83667h7.71783c-0.22498-3.51129-1.58124-6.47113-4.03424-8.8045
            c-2.53613-2.41345-5.84467-3.63696-9.83032-3.63696c-3.52948,0-6.84406,0.88495-9.85254,2.62842
            c-3.01056,1.74445-5.42706,4.13159-7.18268,7.09546c-1.7536,2.9588-2.64154,6.21869-2.64154,9.68939
            c0,4.04242,1.32178,7.45227,3.92883,10.13538c2.6051,2.68213,6.01294,4.04138,10.13037,4.04138
            c4.13971,0,7.86383-1.17987,11.073-3.5072c3.08752-2.23914,5.43311-5.36316,6.97382-9.289h-7.73206
            c-1.10077,1.76068-2.44891,3.1474-4.00995,4.12451c-1.61572,1.01062-3.40179,1.5235-5.30536,1.5235
            c-2.16919,0-3.94104-0.6994-5.26892-2.07794c-1.32379-1.37653-1.99487-3.20111-1.99487-5.42096
            c0-3.03888,1.08868-5.78992,3.23761-8.17908C336.01575,31.37908,338.56812,30.16271,341.44482,30.16271z"/>
          <polygon class="st0" points="392.15729,23.89641 382.85406,23.89641 369.66052,38.36919 374.91522,12.19091 367.25214,12.19091 
            358.53888,55.72271 366.20197,55.72271 369.57336,38.90337 378.73059,55.72271 386.95526,55.72271 377.94196,39.28551 	"/>
          <polygon class="st0" points="312.9798,18.66198 321.08282,18.66198 322.55463,11.51378 314.4516,11.51378 	"/>
          <path class="st0" d="M293.19354,41.05529c-0.48254,2.46924-1.53064,4.50568-3.11395,6.05042
            c-1.6015,1.56-3.51935,2.34943-5.7027,2.35065c-2.05902,0.00116-3.53918-0.58301-4.37085-1.38464
            c-1.896-1.82739-1.64313-4.35437-1.2995-6.242c0.19922-1.09418,3.56396-17.93329,3.56396-17.93329h-7.66309
            c-0.3183,1.59949-3.07953,15.08844-3.23798,15.83453c-0.99176,4.66943-1.46173,9.7254,2.74536,13.68774
            c2.2467,2.11603,5.38037,3.18585,9.23224,3.18585c4.46405,0,8.26318-1.2356,11.29395-3.67242
            c3.02673-2.43372,4.98712-5.84259,5.83044-10.13232l3.77075-18.90338h-7.6347L293.19354,41.05529z"/>
          <path class="st0" d="M67.57185-0.00001H15.93159l-2.87585,14.36914h33.66333l-1.26965-6.31226h2.92633
            c1.31128,0,2.51794,0.71606,3.14618,1.86707l2.42639,4.44519h10.48444c8.37555,0,15.16534,6.78979,15.16534,15.16528v4.48315
            c0,8.37549-6.78979,15.16528-15.16534,15.16528H53.94843l-2.42651,4.44531c-0.62823,1.15112-1.8349,1.86719-3.14618,1.86719
            h-2.92633l1.26971-6.3125H6.08808L0.00001,79.6018h7.17847l23.22656-16.04956h34.8056
            c15.83191,0,29.4635-11.17358,32.5705-26.69775C101.59687,17.7893,87.01508-0.00001,67.57185-0.00001z"/>
          <path class="st0" d="M53.42646,44.37096c1.78387,0.22235,3.47089-0.90741,3.91119-2.65033
            c0.58899-2.3316,1.24359-5.84503,1.24359-9.94464c0-4.10065-0.65436-7.61633-1.24298-9.94922
            c-0.43976-1.7431-2.12622-2.87335-3.91022-2.65167l-10.87927,1.35193c0,0,1.60394,4.63879,1.60394,11.24896
            c0,6.61011-1.60394,11.23938-1.60394,11.23938L53.42646,44.37096z"/>
          <path class="st0" d="M9.97456,42.05339l7.76947,0.96027c0,0-1.61829-4.74567-1.61829-11.08252
            c0-6.33691,1.61829-11.40552,1.61829-11.40552l-7.77197,0.96704c-1.5542,0.19336-2.80182,1.36829-3.08362,2.90887
            c-0.33044,1.80658-0.67963,4.41541-0.67963,7.37213c0,2.95563,0.34894,5.56372,0.6792,7.37036
            C7.16993,40.68578,8.41908,41.86113,9.97456,42.05339z"/>
        </g>
        </svg>
    </Link>

    <div className={navStyles.navbar}>

      <input type="checkbox" className={navStyles.navbartrigger} name="navbar-toggle" id="navbar-toggle"></input>
      <label className={navStyles.navbaricon} for="navbar-toggle">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
          <rect x="4" y="6" width="16" height="2"/>
          <rect x="4" y="11" width="16" height="2"/>
          <rect x="4" y="16" width="16" height="2"/>
        </svg>
      </label>

      <div className={navStyles.collapse}>
        <div className={navStyles.collapseContent}>
          <div className={navStyles.links}>
            <BtnNav to="/encontrar-parking-barcelona/">La app</BtnNav>
            <BtnNav to="/ahorrar-aparcamiento-barcelona/">Ahorra</BtnNav>
            <div className={navStyles.separator}></div>
            <BtnNav to="/parkings/">Para parkings</BtnNav>
          </div>
        </div>
      </div>
      <BtnContained class={props.trialbtnClass}>Descargar gratis</BtnContained>
    </div>
  </nav>
)

Nav.propTypes = {
  siteTitle: PropTypes.string,
  nav: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
  navstate: ``,
}

export default Nav
