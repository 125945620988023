import React from "react"
import { Link } from "gatsby"

import Modal from "./modal"
import { DivisorOr } from "../divisor/divisor"

import modaldownloadStyles from "./modaldownload.module.scss"

import BadgeAppstore from "../../static/app-store-badge.svg"
import BadgeGoogleplay from "../../static/google-play-badge.svg"
import ImageQRdownload from "../images/imageQRdownload"


const ModalDownload = () => (

    <Modal id="openModal" idclose="#close">
            <a href="#close" title="Close" class={modaldownloadStyles.close}>
                <i class="icon-close_24"></i>
            </a>
            <h2>Descarga ya la app de Parquick en tu móvil.</h2>
            <div className={modaldownloadStyles.downloadWrap}>
                <div className={modaldownloadStyles.storesWrap}>
                    Descargar:
                    <Link to="/descargar/descargar-app/" id="Modal-Descargar" className={modaldownloadStyles.storeButton}>
                        <img src={BadgeAppstore} id="app-store" alt="Descargar Parquick en App Store"/>
                    </Link>
                    <Link to="/descargar/descargar-app/" id="Modal-Descargar" className={modaldownloadStyles.storeButton}>
                        <img src={BadgeGoogleplay} id="google-play" alt="Descargar Parquick en Google Play"/>
                    </Link>
                </div>
                <DivisorOr />
                <div className={modaldownloadStyles.qrWrap}>
                    Escanea el código con tu móvil:
                    <ImageQRdownload className={modaldownloadStyles.qr} alt="Descargar Parquick"/>
                </div>
            </div>
    </Modal>
)

export default ModalDownload